/*
 Card Styles  
*/
.card-box {
    &.card-style-1 {
        box-shadow: var(--shadow-lighter);
        background-color: #2BBAD8;
        box-shadow: 0 0 0 0.4rem #7406814a;
        padding: 20px;
        border-radius: 5px;
        margin-top: 30px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), /* sombra más grande y difusa */
                0 6px 6px rgba(0, 0, 0, 0.23); /* sombra secundaria */
        .inner {
            .image {
                img {
                    border-radius: 5px;
                }
            }
            .content {
                padding-top: 20px;
                .title {
                    font-size: 18px;
                    font-weight: 500;
                    margin-bottom: 14px;
                    a {
                        color: inherit;
                        @extend %transition;
                        &:hover {
                            color: var(--color-primary);
                        }
                    }
                }
                .description {
                    margin-bottom: 0;
                }
                .btn-default {
                    margin-top: 20px;
                }
            }
        }
    }
}















